import { CCApiService } from "./api-service";

export interface UIConfig {
  authClientId: string;
  authIssuerUri: string;
  apiBasePath: string;
  launchDarklyEnvId: string;
  googleAnalyticsEnabled: boolean;
  googleAnalyticsTrackingId?: string;
}

export class ConfigService {
  static readonly CONFIG_API = '/ui-config/props.json';

  static ApiService = new CCApiService();

  static _uiConfig: UIConfig;

  static getUIConfig(): UIConfig {
    return this._uiConfig;
  }

  static async initUIConfig() {
    if (!this._uiConfig) {
      return this.ApiService.performFetch(ConfigService.CONFIG_API).then(uiConfig => {
        this._uiConfig = uiConfig;
      });
    } else {
      return Promise.resolve(this._uiConfig);
    }
  }
}
